.Indicador{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
}

.nombre{
    flex: 80%;
    margin:0 1rem;
    font-weight: 700;
}

.Indicador.subfila > .nombre{
    padding-left: 2.5rem;
    font-weight: 400;
}

.estatus{
    flex:20%;
    display: flex;
    justify-content: center;
}

.estatus .Button{
    border: solid 1px #5a2149;
    border-radius: 20px;
    background-color: white;
    color: #5a2149;
    transition: color 200ms ease-in;
}

.estatus .Button:hover,
.Button.Proceso:hover{
    background-color: #5a2149 !important;
    color: white;
}

.Button.Completed{
    border: solid 1px #5a2149;
    background-color: #5a2149 !important;
    color: white;
    border-radius: 20px;
}

.Button.Proceso{
    background: #5a214994;
    color: white;
}