.AdminContainer {
  margin-top: 6rem;
  margin-left: 17rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.AdminContainer > * {
  margin-bottom: 1rem;
  width: 80%;
  max-height: 90vh;
}
