@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

/* * {
  font-family: "PT Sans", sans-serif !important;
} */
/* #5a2149 morado */
/*#00b1b0 verde */
/* #fdb913 amarillo */

body {
  background-color: #f7f7f7;
}

.bold{
  font-weight: 700;
}

.Button {
  background-color: #5a2149;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  transition: 200ms ease-in-out;
}
.Button:active {
  background-color: #00b1b0 !important;
}
.Button:disabled {
  background-color: gray !important;
}

/* input,
textarea {
  border: none;
  padding: 10px;
  border-radius: 3px;
  outline: none;
} */

a {
  margin-top: 5px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: color 200ms ease-in;
}

a.link_router {
  margin-top: 0;
}

a:hover {
  color: #00b1b0;
}

.panel_card {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 10px;
}

.morado {
  background-color: #5a2149 !important;
}

.font-morado {
  color: #5a2149 !important;
}

.azul {
  background-color: #00afaa !important;
}

.img-responsive {
  width: 100%;
  height: auto;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
}

.sticky-top {
  position: sticky;
  top: 0;
}

.on-top{
  z-index: 2000 !important;
}

.y-scroll {
  overflow-y: auto !important;
}
