.Carusel{
    flex: 1;
    position: relative;
}
.Carusel .zoomed{
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0;
    right: -5rem;
    z-index: 3000 !important;
}
.img-carusel{
    max-width: 100% !important;
}

.stepper-carusel{
    position: sticky;
    bottom: 4rem;
}