@import url(https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* * {
  font-family: "PT Sans", sans-serif !important;
} */
/* #5a2149 morado */
/*#00b1b0 verde */
/* #fdb913 amarillo */

body {
  background-color: #f7f7f7;
}

.bold{
  font-weight: 700;
}

.Button {
  background-color: #5a2149;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  transition: 200ms ease-in-out;
}
.Button:active {
  background-color: #00b1b0 !important;
}
.Button:disabled {
  background-color: gray !important;
}

/* input,
textarea {
  border: none;
  padding: 10px;
  border-radius: 3px;
  outline: none;
} */

a {
  margin-top: 5px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: color 200ms ease-in;
}

a.link_router {
  margin-top: 0;
}

a:hover {
  color: #00b1b0;
}

.panel_card {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 10px;
}

.morado {
  background-color: #5a2149 !important;
}

.font-morado {
  color: #5a2149 !important;
}

.azul {
  background-color: #00afaa !important;
}

.img-responsive {
  width: 100%;
  height: auto;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.on-top{
  z-index: 2000 !important;
}

.y-scroll {
  overflow-y: auto !important;
}

.Header{
    background-color:#5a2149 !important;
    z-index: 1201 !important;
}

.Header_icons{
    padding-right: 10%;
    margin-left: auto;
}

.Header_icon{
    color: #ffff;
    margin-right: 10px;
    transition: color 200ms ease-in;
}

.Header_icon:hover{
    color: #edba00;
}
.Login{
    margin-left: 10%;
    margin-right: 10%;
    margin-top:5rem;
    display: flex;
    text-align: center;
    flex-direction: column;
}

.Login_logo_section{
    justify-content: center;
    display: flex;
}

.Login_Headlines > *{
    margin-top: 2rem !important;
    margin-bottom: .5rem !important;
}

.Login_Headlines h4{
    color:gray;
}

.Login_form{
    margin:10px 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    
}

.Login_form .MuiInputBase-root{
    border-radius: 3px 0px 0px 0px;
} 
.Login_form button{
    border-radius: 0px 3px 3px 0px;
    transition: 200ms ease-in;
}

.Login_links{
    margin: 1rem;
    display: flex;
    flex-direction: column;
}





.informacion_dialogo {
  margin: 1rem 0;
  text-align: justify;
}

@media only screen and (min-width: 800px) {
  .informacion_dialogo {
    margin: 1rem 3rem;
  }
}

.button_loading_icon{
    margin-left: .5rem;
    display: none !important;
}

 .button_loading_icon.loading{
    display: block !important;
}
.RondaPanel {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 4rem;
  /*   margin-bottom: 3rem; */
}

@media only screen and (max-width: 480px) {
  .RondaPanel {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.RondaPanel .indicador > *{
  margin-top: 1rem;
  margin-bottom: .5rem;
}

.RondaPanel .Login_logo_section {
  justify-content: space-between;
  align-items: center;
}
.MuiMobileStepper-root {
  max-width: 500px;
  margin: auto;
  flex: 1 1;
}

.opciones_ronda {
  flex-direction: row !important;
  justify-content: center;
}

.RondaPanel .MuiAccordion-root {
  width: 100%;
  margin: 1rem 0 1rem 0;
  box-shadow: none;
}
.RondaPanel .MuiAccordion-root::before{
  content: none;
}
.RondaPanel .MuiAccordionSummary-root{
  cursor: default;
}

.RondaPanel .MuiAccordionSummary-content{
  justify-content: center;
}

.RondaPanel .definicion_fuente{
  margin: 0px 4rem;
}

.RondaPanel .panel_card {
  display: flex;
  min-height: 80vh;
  max-height: 80vh;
  overflow-y: hidden;
}

.RondaPanel .indicador {
  transition: 200ms ease-in-out;
  flex: 0.1 1;
  opacity: 0;
  width: 10%;
  scroll-behavior: smooth;
  padding-right: 1rem;
}

.RondaPanel .indicador.active {
  opacity: 1;
}

.RondaPanel .indicador .form {
  margin-top:2rem;
  align-content: center;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.RondaPanel .indicador .form > *{
  margin-top: 2rem;
}

.RondaPanel .informacion .bottom_button {
  transition: 200ms ease-in-out;
  opacity: 0;
}
@media only screen and (max-width: 600px) {
  .button-stepper {
    font-size: 0 !important;
  }
}

.RondaPanel .informacion .bottom_button.active {
  opacity: 1;
}

.RondaPanel .active {
  flex: 1 1 !important;
}

.RondaPanel .total-indicadores {
  flex: 0.1 1;
  border-left: 1px solid lightgray;
  display: flex;
  transition: 200ms ease-in-out;
}

.total-indicadores .fab {
  margin-bottom: auto;
  margin-top: auto;
  margin-left: -1.7rem;
  transform: scale(0);
  transition: 200ms ease-in;
  color: white;
}

.total-indicadores .fab.hidden {
  transform: scale(1);
}

.total-indicadores .informacion {
  width: 100%;
}

.Carusel{
    flex: 1 1;
    position: relative;
}
.Carusel .zoomed{
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0;
    right: -5rem;
    z-index: 3000 !important;
}
.img-carusel{
    max-width: 100% !important;
}

.stepper-carusel{
    position: -webkit-sticky;
    position: sticky;
    bottom: 4rem;
}
.skeleton{
    margin-left: 5px;
    margin-right: 5px;
    background: linear-gradient(-90deg,#f0f0f0 0%,#f8f8f8 50%,#f0f0f0 100%);
    border-radius: 45px;
    background-size: 400% 400%;
    -webkit-animation: pulse 1.2s ease-in infinite;
            animation: pulse 1.2s ease-in infinite;
}

@-webkit-keyframes pulse{
    0%{
        background-position: 0% 0%;
    }
    100%{
        background-position: -140% 0%;
    }
}

@keyframes pulse{
    0%{
        background-position: 0% 0%;
    }
    100%{
        background-position: -140% 0%;
    }
}
.EditorField-wrapper {
  position: relative;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.09);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: .5rem;
  border-bottom: 1px rgba(0, 0, 0, 0.42) solid;
}
.EditorField-editor {
    background-color: whitesmoke;
    /* border:1px rgba(75, 75, 75, 0.09) solid; */
    min-height: 10rem;
}
.EditorField-toolbar {
  background-color: transparent;
  border: none;
}

 .propuesta_dialog_form > *{
    margin-bottom: 1rem !important;
}
.indicadores_tabla{
    display: flex;
    justify-content: space-around;
    margin-top: 1.5rem;
}
.indicadores_tabla h6{
    color: #5a2149;
}

.indicadores_tabla h6:first-child{
    flex:80% 1;
}
.indicadores_tabla h6:last-child{
    flex:20% 1;
    text-align: center;
}
.Indicador{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
}

.nombre{
    flex: 80% 1;
    margin:0 1rem;
    font-weight: 700;
}

.Indicador.subfila > .nombre{
    padding-left: 2.5rem;
    font-weight: 400;
}

.estatus{
    flex:20% 1;
    display: flex;
    justify-content: center;
}

.estatus .Button{
    border: solid 1px #5a2149;
    border-radius: 20px;
    background-color: white;
    color: #5a2149;
    transition: color 200ms ease-in;
}

.estatus .Button:hover,
.Button.Proceso:hover{
    background-color: #5a2149 !important;
    color: white;
}

.Button.Completed{
    border: solid 1px #5a2149;
    background-color: #5a2149 !important;
    color: white;
    border-radius: 20px;
}

.Button.Proceso{
    background: #5a214994;
    color: white;
}
.PanelUsuario {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 5rem;
}

.PanelUsuario .Login_logo_section {
  justify-content: space-between;
  align-items: center;
}

.PanelUsuario .PanelUsuario_links {
  font-weight: 400;
  text-transform: uppercase;
  margin-right: 2rem;
}

.PanelUsuario .Login_logo_section img {
  height: 4rem;
}

.panel_usuario_informacion {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.informacion {
  text-align: center;
}

.usuario_informacion {
  color: #333333;
}

.bottom_button {
  display: flex;
  justify-content: center !important;
  margin-top: 1rem;
}

.bottom_button Button {
  margin: 5px;
}

.PanelUsuario p {
  font-size: 14px;
  color: #333333 !important;
}

.Drawer > .MuiDrawer-paper{
    max-width: 15rem;
    width: 15rem;
    padding: .5rem;
}
.LoginAdmin{
    text-align: center;
    margin-top:6rem;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LoginAdmin > h2{
    color: gray;
}

.LoginAdmin .Button{
    width: 100%;
    text-align: center;
}

.LoginAdmin .form_login_admin > *{
    margin-bottom: 1rem;
    border-radius: 3px;
    
}
.AdminContainer {
  margin-top: 6rem;
  margin-left: 17rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.AdminContainer > * {
  margin-bottom: 1rem;
  width: 80%;
  max-height: 90vh;
}

.ResultadosPanel{
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 4rem;
}

.ResultadosPanel .resultado{
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.ResultadosPanel .resultado--subfila{
    margin-left: 3rem;
}

.ResultadosPanel .resultado .mayor{
    background-color: #fdb913;
    color: #ffff;
    font-weight: bold;
}
