.ResultadosPanel{
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 4rem;
}

.ResultadosPanel .resultado{
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.ResultadosPanel .resultado--subfila{
    margin-left: 3rem;
}

.ResultadosPanel .resultado .mayor{
    background-color: #fdb913;
    color: #ffff;
    font-weight: bold;
}