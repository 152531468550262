.Login{
    margin-left: 10%;
    margin-right: 10%;
    margin-top:5rem;
    display: flex;
    text-align: center;
    flex-direction: column;
}

.Login_logo_section{
    justify-content: center;
    display: flex;
}

.Login_Headlines > *{
    margin-top: 2rem !important;
    margin-bottom: .5rem !important;
}

.Login_Headlines h4{
    color:gray;
}

.Login_form{
    margin:10px 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    
}

.Login_form .MuiInputBase-root{
    border-radius: 3px 0px 0px 0px;
} 
.Login_form button{
    border-radius: 0px 3px 3px 0px;
    transition: 200ms ease-in;
}

.Login_links{
    margin: 1rem;
    display: flex;
    flex-direction: column;
}




