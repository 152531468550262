.informacion_dialogo {
  margin: 1rem 0;
  text-align: justify;
}

@media only screen and (min-width: 800px) {
  .informacion_dialogo {
    margin: 1rem 3rem;
  }
}
