.EditorField-wrapper {
  position: relative;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.09);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: .5rem;
  border-bottom: 1px rgba(0, 0, 0, 0.42) solid;
}
.EditorField-editor {
    background-color: whitesmoke;
    /* border:1px rgba(75, 75, 75, 0.09) solid; */
    min-height: 10rem;
}
.EditorField-toolbar {
  background-color: transparent;
  border: none;
}
