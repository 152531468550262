.skeleton{
    margin-left: 5px;
    margin-right: 5px;
    background: linear-gradient(-90deg,#f0f0f0 0%,#f8f8f8 50%,#f0f0f0 100%);
    border-radius: 45px;
    background-size: 400% 400%;
    animation: pulse 1.2s ease-in infinite;
}

@keyframes pulse{
    0%{
        background-position: 0% 0%;
    }
    100%{
        background-position: -140% 0%;
    }
}