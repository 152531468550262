.RondaPanel {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 4rem;
  /*   margin-bottom: 3rem; */
}

@media only screen and (max-width: 480px) {
  .RondaPanel {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.RondaPanel .indicador > *{
  margin-top: 1rem;
  margin-bottom: .5rem;
}

.RondaPanel .Login_logo_section {
  justify-content: space-between;
  align-items: center;
}
.MuiMobileStepper-root {
  max-width: 500px;
  margin: auto;
  flex: 1;
}

.opciones_ronda {
  flex-direction: row !important;
  justify-content: center;
}

.RondaPanel .MuiAccordion-root {
  width: 100%;
  margin: 1rem 0 1rem 0;
  box-shadow: none;
}
.RondaPanel .MuiAccordion-root::before{
  content: none;
}
.RondaPanel .MuiAccordionSummary-root{
  cursor: default;
}

.RondaPanel .MuiAccordionSummary-content{
  justify-content: center;
}

.RondaPanel .definicion_fuente{
  margin: 0px 4rem;
}

.RondaPanel .panel_card {
  display: flex;
  min-height: 80vh;
  max-height: 80vh;
  overflow-y: hidden;
}

.RondaPanel .indicador {
  transition: 200ms ease-in-out;
  flex: 0.1;
  opacity: 0;
  width: 10%;
  scroll-behavior: smooth;
  padding-right: 1rem;
}

.RondaPanel .indicador.active {
  opacity: 1;
}

.RondaPanel .indicador .form {
  margin-top:2rem;
  align-content: center;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.RondaPanel .indicador .form > *{
  margin-top: 2rem;
}

.RondaPanel .informacion .bottom_button {
  transition: 200ms ease-in-out;
  opacity: 0;
}
@media only screen and (max-width: 600px) {
  .button-stepper {
    font-size: 0 !important;
  }
}

.RondaPanel .informacion .bottom_button.active {
  opacity: 1;
}

.RondaPanel .active {
  flex: 1 !important;
}

.RondaPanel .total-indicadores {
  flex: 0.1;
  border-left: 1px solid lightgray;
  display: flex;
  transition: 200ms ease-in-out;
}

.total-indicadores .fab {
  margin-bottom: auto;
  margin-top: auto;
  margin-left: -1.7rem;
  transform: scale(0);
  transition: 200ms ease-in;
  color: white;
}

.total-indicadores .fab.hidden {
  transform: scale(1);
}

.total-indicadores .informacion {
  width: 100%;
}
