.LoginAdmin{
    text-align: center;
    margin-top:6rem;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LoginAdmin > h2{
    color: gray;
}

.LoginAdmin .Button{
    width: 100%;
    text-align: center;
}

.LoginAdmin .form_login_admin > *{
    margin-bottom: 1rem;
    border-radius: 3px;
    
}