.Header{
    background-color:#5a2149 !important;
    z-index: 1201 !important;
}

.Header_icons{
    padding-right: 10%;
    margin-left: auto;
}

.Header_icon{
    color: #ffff;
    margin-right: 10px;
    transition: color 200ms ease-in;
}

.Header_icon:hover{
    color: #edba00;
}